import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">NEUTROGENA®</a>
                <span>&gt;</span>
                <a href="/site/produkty/">Opis</a>
                <span>&gt;</span>
                <a href="/site/product/84/" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Skin Detox Wygładzający peeling do
                    twarzy
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/site/assets/Uploads/skin-detox-3.png"
                      alt="skin detox 3"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Skin Detox Wygładzający peeling do
                      twarzy
                    </p>
                  </h2>
                  <dl>
                    <dt>Opis:</dt>
                    <dd>
                      <p>Chłodząca, żelowa formuła peelingu:</p>
                      <p>&nbsp;</p>
                      <ul>
                        <li>
                          <span
                            style={{
                              color: "#222222",
                              "font-family":
                                "Consolas, 'Lucida Console', 'Courier New', monospace"
                            }}
                          >
                            •{" "}
                          </span>
                          pomaga usunąć zanieczyszczenia,
                        </li>
                        <li>
                          <span
                            style={{
                              color: "#222222",
                              "font-family":
                                "Consolas, 'Lucida Console', 'Courier New', monospace"
                            }}
                          >
                            •{" "}
                          </span>
                          działa przeciw szkodliwym czynnikom zewnętrznym,
                        </li>
                        <li>
                          <span
                            style={{
                              color: "#222222",
                              "font-family":
                                "Consolas, 'Lucida Console', 'Courier New', monospace"
                            }}
                          >
                            •{" "}
                          </span>
                          nie zatyka porów.
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                      <p>
                        Delikatne mikrogranulki, wzbogacone o kwas glikolowy,
                        odblokowują pory i delikatnie usuwają cząsteczki brudu,
                        nadmiar sebum oraz makijaż, jednocześnie zachowując
                        nawodnienie.
                      </p>
                      <p>
                        Skóra jest wygładzona, pory dogłębnie oczyszczone – dla
                        jednolitej i rozświetlonej cery.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Stosowanie:</dt>
                    <dd>
                      Stosuj na wilgotną skórę twarzy. Nałóż na dłonie, dodaj
                      wody i delikatnie masuj skórę. Dokładnie spłucz wodą.
                    </dd>
                  </dl>
                  <dl>
                    <dt>Opakowanie:</dt>
                    <dd>150 ml</dd>
                  </dl>
                  <dl className="last">
                    <dt>Cena:</dt>
                    <dd>
                      19,99 pln*{" "}
                      <p>
                        {" "}
                        <br />
                        *cena detaliczna sugerowana przez producenta
                      </p>
                    </dd>
                  </dl>
                </div>
                <div className="fb">
                  <div
                    style={{
                      width: "250px",
                      height: "auto",
                      float: "left"
                    }}
                  >
                    <iframe
                      src="//www.facebook.com/plugins/like.php?href=&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        border: "none",
                        overflow: "hidden",
                        width: "200px",
                        height: "21px"
                      }}
                      allowTransparency="true"
                    />
                  </div>
                </div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobacz podobne produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/70/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-EyeCream-TUBE-FOP.png"
                      alt="HydroBoost EyeCream TUBE FOP"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> żel-krem
                        pod oczy przeciw oznakom zmęczenia
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/71/"
                  >
                    <img
                      src="/site/assets/Uploads/Hydro-Boost-Maska-na-noc.jpg"
                      alt="Hydro Boost Maska na noc"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający krem-maska na noc
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/81/"
                  >
                    <img
                      src="/site/assets/Uploads/prod-2-4.png"
                      alt="prod 2 4"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający booster. Serum do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/82/"
                  >
                    <img
                      src="/site/assets/Uploads/skin-detox-5.png"
                      alt="skin detox 5"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Nawilżający żel do
                        twarzy 2w1
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/83/"
                  >
                    <img
                      src="/site/assets/Uploads/skin-detox-6.png"
                      alt="skin detox 6"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Oczyszczająca maska
                        2w1
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/">
                neutrogena<sup>®</sup> obecnie
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/formula-norweska/">
                czym jest formuła norweska?
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/historia/">poznaj naszą historię</a>
              <span>|</span>
              <a href="/site/produkty/">zobacz produkty neutrogena</a>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;